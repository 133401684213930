import $ from 'jquery';
import 'webpack-jquery-ui';
import 'bootstrap';
import '../sass/app.scss';
import 'formBuilder';
import './custom-fields/choice-buttons';
import 'datatables.net';
import 'datatables.net-bs4';
import axios from 'axios';
import ace from 'ace-builds';
import { html_beautify } from 'js-beautify';
import 'ace-builds/webpack-resolver';
import { customFields, customTemplates, inputSets } from './custom-fields.js';
import Tagify from '@yaireo/tagify';

$(function () {
    $.widget.bridge('uitooltip', $.ui.tooltip);
    $('[data-toggle="tooltip"]').tooltip();
    const loader = $('.loader-wrapper');
    const datatable = $('.datatable');
    datatable.dataTable({ stateSave: true });

    let formBuilder = null;
    const formBuilderDiv = $('.form-builder');
    if (formBuilderDiv.length) {
        const initialFormDataString = formBuilderDiv.attr('data-initial-form-data');
        const formId = formBuilderDiv.data('form-id');

        const oldParagraphs = {};

        const getChangedParagraphs = () => {
            const changedParagraphs = [];
            for (const uid in oldParagraphs) {
                if (!Object.hasOwnProperty.call(oldParagraphs, uid)) {
                    continue;
                }
                const oldParagraph = oldParagraphs[uid];
                const newParagraph = $(`[data-uid="${uid}"] > .prev-holder > div > p`).html();
                if (newParagraph !== oldParagraph) {
                    changedParagraphs.push({
                        oldParagraph,
                        newParagraph,
                    });
                }
            }
            return changedParagraphs;
        };

        const oldFieldLabels = {};
        JSON.parse(initialFormDataString).forEach((field) => {
            if (field.name) oldFieldLabels[field.name] = field.label;
        });
        const getChangedFieldLabels = () => {
            const newFields = formBuilder.actions
                .getData()
                .filter((newField) => newField.type != 'hidden' && newField.label != undefined);

            const changedFieldLabels = [];
            for (const name in oldFieldLabels) {
                if (!Object.hasOwnProperty.call(oldFieldLabels, name)) {
                    continue;
                }
                const oldFieldLabel = oldFieldLabels[name];
                const newField = newFields.find((newField) => newField.name == name);
                if (newField && newField.label !== oldFieldLabel) {
                    changedFieldLabels.push({
                        oldFieldLabel,
                        newFieldLabel: newField.label,
                    });
                }
            }
            return changedFieldLabels;
        };

        const saveAcrossAllForms = () => {
            axios
                .post('/form/save-across-all-forms', {
                    paragraphs: getChangedParagraphs(),
                    fieldLabels: getChangedFieldLabels(),
                })
                .then(() => (window.location = `/form/${formId}/edit?success=Sucessfully saved`));
        };

        if(document.querySelector('.read-only')) {
            formBuilder = formBuilderDiv.formBuilder({
                disableFields: ['file','autocomplete','button','checkbox-group','date','header','hidden','number','paragraph','radio-group','select','starRating','text','textarea','radio-group','pageBreak','years','address','countries'],
                showActionButtons: false,
                formData: initialFormDataString,
                fields: customFields,
                templates: customTemplates,
                inputSets: inputSets,
                dataType: 'json',
                typeUserEvents: {
                    paragraph: {
                        // assign a unique ID to each already-existing
                        // paragraph item and remember its initial value
                        onadd: function (field) {
                            const uid = Math.random();
                            $(field).attr('data-uid', uid);
                            oldParagraphs[uid] = $(field).find('.prev-holder > div > p').html();
                        },
                    },
                },
            });

        } else {
            formBuilder = formBuilderDiv.formBuilder({
                disableFields: ['file'],
                formData: initialFormDataString,
                fields: customFields,
                templates: customTemplates,
                inputSets: inputSets,
                dataType: 'json',
                typeUserEvents: {
                    paragraph: {
                        // assign a unique ID to each already-existing
                        // paragraph item and remember its initial value
                        onadd: function (field) {
                            const uid = Math.random();
                            $(field).attr('data-uid', uid);
                            oldParagraphs[uid] = $(field).find('.prev-holder > div > p').html();
                        },
                    },
                },
                onSave: (e, formData) => {
                    const name = $('input[name=name]').val();
                    const scripts = $('input[name=scripts]').val();
                    axios
                        .put(`/form/${formId}`, {
                            form_fields: formData,
                            name: name,
                            scripts: scripts,
                        })
                        .then(() => (window.location = `/form/${formId}/edit?success=Sucessfully saved`));
                },
                actionButtons: [
                    {
                        /*
                            Normally it's better to query this button using its ID, but
                            formbuilder will transform the ID into something like
                            frmb-1658157501983-saveAcrossAllFormsButton-action,
                            which is hard to query. In this case it's easier to add a
                            "saveAcrossAllFormsButton" class to the button and query
                            that.

                            It's still necessary to specify an ID, otherwise formbuilder
                            won't display the button.
                        */
                        id: 'saveAcrossAllFormsButton',
                        className: 'btn btn-warning saveAcrossAllFormsButton mt-2',
                        label: 'Save Across All Forms',
                        type: 'button',
                        disabled: true,
                        events: {
                            click: function () {
                                $('#updateList').empty();
                                for (const paragraph of getChangedParagraphs()) {
                                    $('#updateList').append(`<div class='row'>
                                        <div class='col-md-6'>${paragraph.oldParagraph}</div>
                                        <div class='col-md-6'>${paragraph.newParagraph}</div>
                                    </div>`);
                                }
                                for (const label of getChangedFieldLabels()) {
                                    $('#updateList').append(`<div class='row'>
                                        <div class='col-md-6'>${label.oldFieldLabel}</div>
                                        <div class='col-md-6'>${label.newFieldLabel}</div>
                                    </div>`);
                                }
                                $('#saveAcrossAllFormsModal').modal('show');
                            },
                        },
                    },
                ],
                onCloseFieldEdit: (editPanel) => {
                    if (getChangedParagraphs().length === 0 && getChangedFieldLabels().length === 0) return;
                    $('.saveAcrossAllFormsButton').attr('disabled', false);
                },
            });
        }

        $('#saveAcrossAllFormsConfirmButton').on('click', () => {
            saveAcrossAllForms();
        });
    }

    datatable.on('click', '.version-revert', (e) => {
        if (confirm('Are you sure you want to restore the form with this version?')) {
            const url = $(e.currentTarget).data('url');
            axios.get(url).then(() => (window.location = '/form?success=Sucessfully restored version'));
        }
    });

    datatable.on('click', '.form-delete', (e) => {
        if (confirm('Are you sure you want to delete this form?')) {
            const url = $(e.currentTarget).data('url');
            axios.delete(url).then(() => (window.location = '/form?success=Sucessfully deleted'));
        }
    });

    datatable.on('click', '.script-delete', (e) => {
        const timesUsed = $(e.currentTarget).data('used');
        const message = `Are you sure you want to delete this script? It is currently being used by ${timesUsed} form${
            timesUsed != 1 ? 's' : ''
        }`;

        if (confirm(message)) {
            const url = $(e.currentTarget).data('url');
            axios.delete(url).then(() => (window.location = '/script?success=Sucessfully deleted'));
        }
    });

    $('.account-user-delete').on('click', (e) => {
        if (confirm('Are you sure you want to remove this user from the account?')) {
            const url = $(e.currentTarget).data('url');
            axios
                .delete(url)
                .then(() => (window.location = window.location.pathname + '?success=User removed from account.'));
        }
    });

    //code editor

    if ($('#editor').length) {
        var editor = ace.edit('editor');
        var editorHtml = ace.edit('editorHtml');

        var editorForm = $('#editor-form');
        var editorInput = $('#editor-input');
        var editorSubmit = $('#editor-submit');
        var editorResults = $('#editor-results');
        var editorStyles = $('#editor-styles');
        var backgroundColor = $('input[name="preview_background"]', editorForm);

        editorSubmit.on('click', function (e) {
            e.preventDefault();

            var code = editor.getValue();

            editorInput.val(code);
            editorForm.submit();
        });

        editor.session.setMode('ace/mode/css');
        editor.setTheme('ace/theme/dracula');

        editorHtml.session.setMode('ace/mode/html');
        editorHtml.setReadOnly(true);
        editorHtml.setTheme('ace/theme/dracula');

        function updateCSS() {
            var editorValue = editor.getValue();
            editorStyles.html(editorValue);
        }

        editor.on('input', updateCSS);
        updateCSS();

        $('#nav-html-tab').on('click', function () {
            const html = editorResults.html();
            editorHtml.session.setValue(html_beautify(html));
        });

        editorResults.css('background-color', backgroundColor.val());
        backgroundColor.on('change', function () {
            editorResults.css('background-color', backgroundColor.val());
        });
    }

    if ($('#js-editor').length) {
        var editor = ace.edit('js-editor');
        var editorInput = $('#editor-input');

        editor.session.setMode('ace/mode/javascript');
        editor.setTheme('ace/theme/dracula');

        if (editorInput.attr('disabled')) {
            editor.setReadOnly(true);
        }

        function updateJs() {
            editorInput.val(editor.getValue());
        }

        editor.on('input', updateJs);
        updateJs();
    }

    // Manual Unbounce Refresh
    $('.pages-refresh-button').on('click', () => {
        loader.show();
    });

    // Tag input
    var tagInput = document.querySelector('.tag-input');
    const tagify = new Tagify(tagInput, {
        whitelist: [].concat(window.WHITELISTED_TAGS),
    });
    const editTagsModal = $('#editTagsModal');
    datatable.on('click', '.edit-tags-btn', ({ currentTarget }) => {
        const id = $(currentTarget).data('id');
        const tags = $(currentTarget)
            .siblings('.tags-list')
            .children()
            .map((_, el) => $(el).html())
            .get();

        $('input[name="id"', editTagsModal).val(id);
        tagify.removeAllTags();
        tagify.addTags(tags);

        editTagsModal.modal('show');
    });

    $('.editTagsFormSubmit').on('click', ({ currentTarget }) => {
        const form = $(currentTarget).closest('form');
        const url = form.attr('action');
        const id = $('input[name="id"', form).val();

        $.ajax({
            type: 'POST',
            url: url,
            data: form.serialize(), // serializes the form's elements.
            success: (tags) => {
                const tagsList = $(`.tags-list-${id}`);
                tagsList.empty();
                tags.forEach((tag) => tagsList.append(`<span>${tag}</span>`));

                editTagsModal.modal('hide');
            },
            failure: () => editTagsModal.modal('hide'),
        });
    });

    // Script tags
    var scriptInput = document.querySelector('input[name="scripts"]');
    var scriptTags = new Tagify(scriptInput, {
        whitelist: [].concat(window.WHITELISTED_TAGS),
        enforceWhitelist: true,
        dropdown: {
            maxItems: 20,
            enabled: 0,
            closeOnSelect: false,
            mapValueTo: 'value',
        },
    });

    // Move account
    const moveAccountModal = $('#moveAccountModal');
    datatable.on('click', '.move-account-btn', ({ currentTarget }) => {
        const url = $(currentTarget).data('url');
        $('form', moveAccountModal).attr('action', url);
        moveAccountModal.modal('show');
    });
});
