if (!window.fbControls) window.fbControls = new Array();
window.fbControls.push(function (controlClass, allControlClasses) {
    class controlChoiceButtons extends allControlClasses.select {
        static get definition() {
            return {
                defaultAttrs: {
                    radioType: {
                        label: 'Type',
                        options: {
                            radio: 'Radio',
                            buttons: 'Choice Buttons',
                        },
                    },
                },
            };
        }

        build() {
            if (this.config.radioType != 'buttons') return super.build();
            const options = this.config.values;
            let hasIcons = options.some((option) => option.label.indexOf('|') !== -1);
            return this.markup(
                'div',
                options.map((option, i) => {
                    const id = `${this.config.id}-${i}`;
                    let icon, label;
                    if (option.label.indexOf('|') !== -1) {
                        [icon, label] = option.label.split('|');
                    } else {
                        label = option.label;
                    }
                    const iconMarkup = icon
                        ? this.markup(
                              'div',
                              this.markup('i', null, {
                                  className: icon,
                              }),
                              { className: 'icon-container' },
                          )
                        : undefined;
                    return this.markup(
                        'div',
                        [
                            this.markup('input', null, {
                                type: 'radio',
                                name: this.config.name,
                                value: option.value,
                                id,
                            }),
                            this.markup('label', [iconMarkup, label], { for: id }),
                        ],
                        { className: 'choice-button' },
                    );
                }),
                { className: hasIcons ? 'icon-choice-buttons' : 'choice-buttons' },
            );
        }
        onRender() {
            return super.onRender();
        }
    }

    allControlClasses.select.register('radio-group', controlChoiceButtons);
    return controlChoiceButtons;
});
